.container-section-industries{
    margin-top:-1px;
    font-family: 'Aileron';
    text-align: center;
    background-color:#FAFAFA;
    color: #005587;
}
.section-industries-header{
    font-family: 'Aileron';
    padding-bottom: 5vh;
}
.section-industries-description{
    font-family: 'Aileron';
    color:black;
    text-align: justify;
    padding-bottom: 4vh;
}
.industries-list-box{
    overflow-wrap: break-word;
    width: 100%;
}
.mobile-section-background{
    width:100vw;
}
.section-industries-background{
    object-fit: cover;
    width: inherit;
    height: 100%;
}
.title-box{
    width: 100%;
}