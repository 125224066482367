.section-contact-container{
    font-family: 'Aileron';
    text-align: center;
    background-color: #005587;
    margin-top: -5px;
}
.contact-form-container{
    background-color: white;
    border-radius: 25px;
    margin-top:2vh;
    padding-top: 3vh;
    padding-bottom: 3vh;
}
.contact-form-header{
    color: #005587;
    padding-bottom: 4vh;
}
.contact-form-description{
    padding-left: 4vw;
    padding-right: 4vw;
    padding-bottom: 4vh;
    text-align: justify;
}
.contact-form-grid{
    padding-left: 4vw;
}
.form-field{
    padding-right: 4vw;
    padding-bottom: 3vh;
}
.btn-send-form{
    height:40px;
    border-radius: 20px!important;
    padding-right: 4vw!important;
    padding-left: 4vw!important;
    
}
.contact-box-container{
    display: flex;
}
.contact-box{
    margin: auto;
    width:100%;
    text-align: left;
    padding-left: 4vw;
    padding-right: 4vw;
}