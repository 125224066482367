.swiper .swiper-slide{
    height:auto;
}
.slide-container{
    
    background-color: cyan;
}
.card-container{
    font-family: 'Aileron', sans-serif;
    height: 100%;
    background-color: white;
    text-align: center;
    align-items: center;
    padding-top:5vh;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 3vh;
}
.card-icon-container{
    width:96px;
    height:96px;
    margin: auto;
    padding-bottom: 2em;
}
.card-icon{
    width:100%;
}
.card-title{
    font-weight: bold!important;
    padding-bottom: 2em;
}
.card-description{
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    padding-bottom: 2em;
}
.technology-icon{
    text-align:center;
    max-width:95%;
}
