.container-section-about{
    font-family: 'Aileron';
    text-align: center;
    color: black;
    background-color: #FAFAFA;
    margin: auto;
}
.section-about-grid{
    display: flex;
}
.section-about-box{
    margin:auto;
}
.section-about-header{
    padding-top: 4vh;
    padding-bottom: 3vh;
    color:#005587;
}
.section-about-description{
    padding-bottom: 4vh;
    text-align: justify;
}
.section-about-description b{
    color:#005587;
}
.img-greedy-definition{
    width:100%;
}