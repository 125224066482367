.container-footer{
    font-family: 'Aileron';
    background-color:white;
    color:black;
    text-align: center;
}
.footer-content{
    padding-top:3vh;
    width:100%;
    padding-bottom: 1vh;
}
.footer-grid-contact{
    height:64px;
}
.footer-grid-contact-info{
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
    padding-left: 1em;
}
.grid-social-media{
    margin: auto;
}
.social-media-button{
    margin:auto;
}
.social-media-icon{
    color: #005587;
}
.grid-legal{
    border-top: 1px solid gray;
    margin-top: 1em!important;
    padding-top: 1vh;
}
.grid-legal-links{
    display: flex;
    margin: auto;
}
.grid-legal-box{
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
}
.legal-link{
    color: #005587 !important;
}
.legal-link:hover{
    cursor: pointer;
    text-decoration: underline;
}
.logo-imagotype{
    height:48px;
}