@font-face{
  font-family: 'Aileron';
  src: url('./assets/Aileron-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: 'Aileron', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
