.legal-container{
    background-color: #E3E4E5;
    width: 100vw;
}
.navbar-solid{
    background-color: white!important;
    color: #005587!important;
    vertical-align: middle;
}
.mobile-navbar-logo{
    width:100%;
}
.mobile-navbar-logo img{
    margin: auto;
    height:54px;
}
.navbar-logo:hover{
    cursor: pointer;
}
.navbar-logo img{
    height:54px;
}
.legal-grid{
    margin-top: 100px!important;
    margin-bottom: 5vh!important;
}
.legal-box{
    background-color: white;
    border-radius: 10px;
    padding-top: 4vh;
    padding-bottom: 2vh;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}