.container-section{
    font-family: 'Aileron';
    text-align: center;
    background-color: #e1e1e1;
    margin-top: -1vh;
}
.section-content{
    padding-top:4vh;
    width:100%;
    padding-bottom: 5vh;
}
.section-header{
    font-family: 'Aileron';
    color: #005587;
    padding-bottom: 5vh;
}
.section-description{
    font-family: 'Aileron';
    text-align: justify;
    padding-bottom: 7vh;
}