.landing-container{
    width: 100vw;
}
.navbar-transparent{
    background-color: transparent!important;
    box-shadow: none!important;
}
.navbar-solid{
    background-color: white!important;
    color: #005587!important;
    vertical-align: middle;
}
.navbar-toolbar{
    margin-left: 2vw;
    height:80px;
}
.mobile-navbar-logo{
    width:100%;
}
.mobile-navbar-logo img{
    margin-left: 12vw;
    height:54px;
}
.navbar-logo:hover{
    cursor: pointer;
}
.navbar-logo img{
    height:54px;
}
.navbar-page{
    margin-left:4vw;
}
.navbar-page:hover{
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 10px;
}
.container-video{
    position:relative;
}
#background-video{
    position:relative;
    width:100%;
    z-index: 0;
}
.video-overlay{
    position:absolute;
    color: white;
    top: 40vh;
    right: 10vw;
    z-index:1;
}
.background-image{
    width:100vw;
}